import React, { FC } from "react";
import { Box, theme } from "flicket-ui";
import { upperFirst } from "lodash";
import {
  BarChart,
  XAxis,
  Tooltip,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Bar,
} from "recharts";

import { useIsMobile } from "~hooks";

import { Legend } from "./Legend";

export const Chart: FC<{
  data?: { name: string; issued: number; scanned: number; toCome: number }[];
}> = ({ data }) => {
  const isMobile = useIsMobile();

  const options = {
    chart: {
      margin: {
        top: 20,
        right: 0,
        left: 0,
        bottom: data.length > 15 && data.length <= 30 ? 70 : 24,
      },
    },
    x: {
      axisLine: false,
      tickLine: false,
      interval: 0,
      tick: {
        dy: data.length > 15 ? 35 : 8,
        fontSize: 14,
        color: theme.colors.N500,
      },
      angle: isMobile ? -30 : data.length > 15 ? -75 : 0,
      hide: data.length > 30 || isMobile,
    },
    y: {
      axisLine: false,
      tickLine: false,
      tick: { dx: -16, fontSize: 12, color: theme.colors.N500 },
      padding: { left: 0, top: 0, right: 0, bottom: 0 },
      allowDecimals: false,
    },
    grid: {
      strokeDasharray: "0",
      vertical: false,
      stroke: theme.colors.N200,
    },
    tooltip: {
      itemStyle: { fontSize: 14, color: theme.colors.N800 },
      contentStyle: { fontSize: 14, color: theme.colors.N800 },
      labelStyle: { fontSize: 14, fontWeight: "bold", marginBottom: 12 },
    },
  };

  return (
    <Box height="300px" mb={4}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} {...options.chart}>
          <CartesianGrid {...options.grid} />
          <XAxis dataKey="name" {...options.x} />
          <YAxis {...options.y} />
          <Tooltip
            {...options.tooltip}
            formatter={(value, name) => [
              value,
              name === "toCome" ? "To come" : upperFirst(name),
            ]}
          />
          <Legend
            payload={[
              { name: "Issued", fill: theme.colors.P200 },
              { name: "Scanned", fill: theme.colors.P300 },
              { name: "To come", fill: theme.colors.P100 },
            ]}
          />
          <Bar dataKey="issued" fill={theme.colors.P200} />
          <Bar dataKey="scanned" fill={theme.colors.P300} />
          <Bar dataKey="toCome" fill={theme.colors.P100} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
