import React, { FC } from "react";

import { Text, Flex, Box, SystemProps } from "flicket-ui";
import { Icon } from "~components";
import { IconProps } from "../common/Icon/Icon";
import ReactTooltip from "react-tooltip";

export const Card: FC<
  {
    id: string;
    stat: string;
    title: string;
    icon?: string;
    infoToolTip?: string;
  } & SystemProps &
    IconProps
> = ({ title, id, stat, bg, color, icon, infoToolTip }) => (
  <Box>
    <Box bg={bg || "P100"} borderRadius="sm" p={2} key={id}>
      <Flex alignItems="center" justifyContent="flex-start" mb="6/4">
        <Flex
          bg={color || "P300"}
          borderRadius="full"
          variant="center"
          mr={1}
          width={36}
          height={36}
          lineHeight="normal"
        >
          <Icon color="white" fontSize={4} icon={icon} />
        </Flex>
        <Text
          color="N600"
          fontSize={4}
          fontWeight="extraBold"
          lineHeight="normal"
        >
          {title}
        </Text>
        {infoToolTip && (
          <>
            <Icon data-tip={infoToolTip} icon={"info"} ml={1} fontSize={6} />
            <ReactTooltip
              effect="solid"
              textColor="white"
              borderColor="black"
              backgroundColor="black"
              multiline={true}
            />
          </>
        )}
      </Flex>
      <Text fontWeight="extraBold" fontSize={6} color="N600">
        {stat}
      </Text>
    </Box>
  </Box>
);
